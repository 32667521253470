<template>
  <div class="vd_sidebar">
    <!--        background-color="$color-white"-->
    <!--        text-color="#000"-->
    <!--        active-text-color="$color-white"-->
    <el-menu class="vd_sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" unique-opened router>
      <template v-for="item in items">
        <template v-if="item.perm_child_list">
          <el-submenu :index="item.perm_url + '_list' + `?perm_id=${item.perm_id}`" :key="item.perm_url + '_list'">
            <template slot="title">
              <i :class="item.perm_icon"></i>
              <span slot="title">{{ item.perm_name }}</span>
            </template>
            <template v-for="subItem in item.perm_child_list">
              <el-submenu
                v-if="subItem.perm_child_list"
                :index="subItem.perm_url + '_list' + `?perm_id=${subItem.perm_id}`"
                :key="subItem.perm_url + '_list' + `?perm_id=${subItem.perm_id}` + `?perm_id=${item.perm_id}`"
              >
                <template slot="title">{{ subItem.perm_name }}</template>
                <el-menu-item
                  v-for="(threeItem, i) in subItem.perm_child_list + '_list' + `?perm_id=${subItem.perm_id}`"
                  :key="i"
                  :index="threeItem.perm_url + `?perm_id=${threeItem.perm_id}`"
                  >{{ threeItem.perm_name }}</el-menu-item
                >
              </el-submenu>
              <el-menu-item
                v-else
                :key="(skipPageNameList.includes(subItem.perm_url) ? subItem.perm_url : subItem.perm_url + '_list') + `?perm_id=${subItem.perm_id}`"
                :index="(skipPageNameList.includes(subItem.perm_url) ? subItem.perm_url : subItem.perm_url + '_list') + `?perm_id=${subItem.perm_id}`"
                >{{ subItem.perm_name }}</el-menu-item
              >
            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.perm_url" :key="item.perm_url">
            <i :class="item.perm_icon"></i>
            <span slot="title">{{ item.perm_name }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from '@/components/common/bus';
import { get } from '@/api/request';
import { userAPI } from '@/api/modules/user';

export default {
  data() {
    return {
      collapse: false,
      items: [
        {
          icon: 'el-icon-s-home',
          index: 'dashboard',
          title: '系统首页'
        }
      ],
      skipPageNameList: [
        'makeSampleControlTable', // 打样控制表
        'designStatisticalDraft', // 设计图稿统计
        'statisticsOfCompletedTransactions', // 成品成交统计
        'mpc_statistics', // 材料采购合同统计
        'fppc_statistics', // 成品采购合同统计
        'ccp_statistics', // 分类客户打样统计
        'sca_statistics', // 销售合同金额统计
        'fscapm_statistics', // 每月首次销售合同金额统计
        'msa_statistics', // 月度出运金额统计
        'sd_statistics', // 出运明细统计
        'msds_statistics', // 出运明细统计
        'pomodrdetail_statistics', // 成品材料货号数量汇总
        'pomodrsum_statistics', // 成品材料货号数量明细
        'pomodrbycust_statistics', // 成品材料货号数量 （按客户）
        'suppmonthly_statistics' // 供应商月度数据汇总表
      ]
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace('/', '');
    }
  },
  created() {
    this.getMenus();
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on('collapse', msg => {
      this.collapse = msg;
      bus.$emit('collapse-content', msg);
    });
  },
  methods: {
    getMenus() {
      get(userAPI.logMenu)
        .then(res => {
          if (res.data) {
            this.items = res.data;
          } else {
            this.items = null;
          }
        })
        .catch(() => {
          this.$router.push('/login');
          this.deleteCookie();
        });
    },
    // 删除cookie
    deleteCookie() {
      get(userAPI.userLogout).then(res => {
        if (res.data.code === 0) {
          this.$cookies.remove('userInfo');
          this.$cookies.remove('push');
        }
      });
    }
  }
};
</script>

<style scoped>
.vd_sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.vd_sidebar::-webkit-scrollbar {
  width: 0;
}
.vd_sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.vd_sidebar > ul {
  height: 100%;
}
</style>
